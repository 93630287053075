@import 'styles/colors.scss';

.client-info-root {
  width: 100%;
  display: flex;
  background-color: $grey-section;

  .client-info-title {
    color: $dark-grey;
  }

  .client-info-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 20px;
    position: relative;

    .header-actions {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;

      .flex {
        display: flex;

        button {
          display: flex;
          cursor: pointer;
          align-items: center;
        }
      }
    }
    
    .export-icon {
      background: url(exportIcon.svg) no-repeat;
      width: 16px;
      margin-left: 5px;
      height: 16px;
    }
  }
}
