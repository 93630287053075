@import "styles/colors.scss";

.janitorial-company-root {
  width: 100%;
  display: flex;
  background-color: $grey-section;

  .janitorial-company-title {
    color: $dark-grey;
  }

  .janitorial-info {
    padding-left: 10px;
    color: $medium-grey;
  }

  .janitorial-company-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 20px;
  }

  .button-items-input {
    display: flex;
    width: 100%;
    background-color: $white;
    align-items: center;
    margin-top: 10px;
  }
  .button-items-label {
    padding: 10px;
  }
}
