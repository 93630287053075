@import './colors.scss';

input {
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;

  padding-left: 8px;
  font-family: 'OpenSans-SemiBold';
  font-size: 16px;
  height: 52px;
}

label {
  font-family: 'OpenSans-SemiBold';
  font-size: 0.75rem;
  color: white;
  text-align: start;
  width: 100%;
  padding-left: 4px;
}

.capitalize-first {
  text-transform: capitalize;
}

.auto-capitalize {
  text-transform: uppercase;
}

::-webkit-input-placeholder {
  text-transform: none;
}

:-moz-placeholder {
  text-transform: none;
}

::-moz-placeholder {
  text-transform: none;
}

:-ms-input-placeholder {
  text-transform: none;
}

.no-capitalize {
  text-transform: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  height: 52px;
  border-width: 0px;
  border-radius: 0;
  background-color: transparent;
  font-family: 'OpenSans-SemiBold';
  color: white;
  font-size: 16px;
  padding-left: 8px;
  padding-right: 8px;
  border-color: $white;
  border-width: 1px;
  border-style: solid;
}

select:invalid {
  color: white;
  opacity: 0.5;
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}

textarea,
.table-container,
.notes-textarea {
  &::-webkit-scrollbar {
    width: 5px !important;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $primary-color !important;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5) !important;
  }

  &::-webkit-scrollbar-thumb:window-inactive {
    background: $primary-color !important;
  }
}

.input-label-content input.input-box {
  border: 1px solid grey !important;
  background-color: #ffffff;
}