@import '../../../styles/colors.scss';
.add-photo-modal-root-div div.save-as-backdrop {
  background-color: rgba(0, 0, 0, 0.8);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  &:after{
      content: 'X';
      color: #fff;
      position: absolute;
      right: 10px;
      top: 7px;
      font-size: 20px;
  }
}

.preview-photo-root-div div.save-as-backdrop {
  background-color: rgba(0, 0, 0, 0.8);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  &:after{
      content: 'X';
      color: #fff;
      position: absolute;
      right: 10px;
      top: 7px;
      font-size: 20px;
  }
}

.preview-photo-modal-hide {
    display: none;
}
.preview-photo-modal-root-div {
    // position: fixed;
    // top: 0;
    // left: 0;
    // right: 0;
    // bottom: 0;
    z-index: 4;
    //background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    //position: absolute;
    position: fixed;
    max-width: calc(100% - 40px);
    max-height: calc(100% - 60px);
    margin-top: 5px;
    img{
        display: block;
        max-width: 100%;
        max-height: -webkit-fill-available;
    }
}
.preview-photo-modal-content-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    width: 400px;
    height: 400px;
    border-radius: 12px;
    p {
        color: $primary-color;
        font-size: 24px;
        padding-top: 48px;
    }
}
