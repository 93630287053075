@import '../../../styles/colors.scss';

tbody.full-width {
  width: 100%;
}

.section-rating-modal-root-div {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.section-rating-modal-content-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  width: 80vw;
  height: 70vh;
  overflow: scroll;
}

.modal-title-container {
  display: flex;
  width: 100%;
  min-height: 5vh;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: $primary-color;


  h3 {
    color: white;
    margin: 0px;
  }

  .edit-icon {
    width: 20px;
    height: 20px;
    background: url('../../../assets/icons/edit.png') no-repeat;
    background-position: center;
    background-size: contain;
    padding-left: 20px;
    margin: 0px;
  }

  .title-input {
    background-color: $primary-color;
    border-width: 0px;
    font-family: 'OpenSans-BoldItalic';
    font-size: 18px;
    line-height: 24px;
    color: #fff;
    font-weight: bold;
    max-height: 5vh;
    min-width: 80px;
    max-width: 80%;
  }
}

.modal-sections-indicators {
  display: flex;
  width: 76vw;
  min-height: min-content;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  line-height: normal;

  %area-container {
    display: flex;
    height: 42px;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 24px;

    span {
      margin: 0px;
      color: white;
      font-size: 12px;
      text-align: center;
    }
  }

  .area-one {
    @extend %area-container;
    background-color: $are-one-color;
  }

  .area-two {
    @extend %area-container;
    background-color: $are-two-color;
  }

  .area-three {
    @extend %area-container;
    background-color: $are-three-color;
  }

  .area-four {
    @extend %area-container;
    background-color: $are-four-color;
  }

  .area-five {
    @extend %area-container;
    background-color: $are-five-color;
  }
}

.add-new-item-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 76vw;
  height: 52px;
  border-radius: 8px;
  background-color: $medium-grey;
  color: white;
  font-size: 18px;
  font-family: 'OpenSans-SemiBold';
  text-decoration: underline;
  margin-top: 24px;
  margin-bottom: 36px;
  border-width: 0px;
}

.rating-modal-done-button {
  position: relative;
  top: -24px;
}

.section-list-container {
  display: flex;
  width: 76vw;
  min-height: min-content;
  margin-top: 24px;
  //margin-bottom: 24px;
  margin-bottom: 50px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;


  .section-buttons-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    padding-top: 20px;
  }

  .section-item-container {
    display: flex;
    width: 76vw;
    height: 64px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: black;
    border-bottom-width: 0.5px;
    border-bottom-color: $medium-grey;
    ;
    border-bottom-style: solid;

    .section-item-title {
      padding-left: 12px;
      align-items: center;
      justify-content: flex-start;
      width: 42%;
      font-family: 'OpenSans-SemiBold';
      color: $dark-grey;

      input {
        border-width: 0px;
        padding-left: 0px;
      }
    }

    .section-item-rating {
      display: flex;
      width: 42%;
      height: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      %button-rate {
        width: 50px;
        height: 50px;
        border-width: 1px;
        border-style: solid;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .button-no-rate {
        @extend %button-rate;
        border-color: $medium-grey;
        color: $dark-grey;
      }

      .button-rate-one {
        @extend %button-rate;
        border-color: $are-one-color;
        background-color: $are-one-color;
        color: white;
      }


      .button-rate-two {
        @extend %button-rate;
        border-color: $are-two-color;
        background-color: $are-two-color;
        color: white;
      }

      .button-rate-three {
        @extend %button-rate;
        border-color: $are-three-color;
        background-color: $are-three-color;
        color: white;
      }


      .button-rate-four {
        @extend %button-rate;
        border-color: $are-four-color;
        background-color: $are-four-color;
        color: white;
      }


      .button-rate-five {
        @extend %button-rate;
        border-color: $are-five-color;
        background-color: $are-five-color;
        color: white;
      }

      .button-unselected-day {
        border-right-width: 0;
      }

    }

    .disable {
      opacity: 0.5;
    }


    .section-actions-container {
      display: flex;
      width: 16%;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      span {
        text-decoration: underline;
        font-size: 12px;
        margin: 0px;
      }

      .trash-icon {
        width: 20px;
        height: 20px;
        background: url('../../../assets/icons/delete-button.svg') no-repeat;
        background-position: center;
        background-size: contain;
        margin: 0px;
      }
    }
  }
}
