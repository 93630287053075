html {
  // overflow: hidden;
  // height: 100%;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}
body {
  // height: 100%;
}

#root {
  // height: 100%;
  // overflow: scroll;
}


html {
  overflow: hidden;
  height: 100%;
  -webkit-overflow-scrolling: touch;
}
body {
  height: 100%;
}

#root {
  height: 100%;
  overflow: scroll;
}

html.scroll-fix{
  overflow: hidden !important;
  height: 100% !important;
  -webkit-overflow-scrolling: touch;
}

body.scroll-fix{
  height: 100% !important;
}

#root.scroll-fix{
  overflow: scroll !important;
  height: 100% !important;
}

.bid-form-root {
  // overflow-x: hidden;
  overflow: unset;
}

.bid-form-content-screen {
  position: relative;
  overflow: unset;
}

.rc-tooltip-inner {
  display: flex;
  justify-content: center;
  align-items: center;
}
