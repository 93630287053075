@import 'styles/colors.scss';

.sun-editor .se-resizing-bar {
  display: none !important;
}

.sun-editor .se-toolbar {
  z-index: auto;
}
.se-wrapper-inner.se-wrapper-wysiwyg.sun-editor-editable {
  padding-top: 0;
}
.sun-editor .se-tooltip:hover .se-tooltip-inner{
  z-index: 2;
}

.sun-editor-editable p{
  margin-bottom: 0 !important;
}
.additional-quotes-root {
  * {
    box-sizing: border-box;
  }

  .suggestion-counter {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 1;
    visibility: hidden;
  }

  width: 100%;
  display: flex;
  background-color: $white;

  .additional-quotes-title {
    color: $dark-grey;
    margin-bottom: 15px;
  }

  .additional-quotes-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 20px;
  }

  .additional-quotes-row {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
    height: 66px;
    & > div {
      margin: 0 5px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .additional-quotes-ipad-wrapper {
    @media (max-width: 769px) {
      font-size: 12px;
    }
  }

  .quote-add-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ddd;
    padding: 5px;
    width: 100px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 12px;
    margin-top: 10px;
    border: none;
    outline: none;
    color: #333;
  }

  .disabled-button {
    opacity: 0;
  }

  .quote-option-content {
    display: flex;
    margin-bottom: 10px;
  }

  .quote-content-space {
    margin-bottom: 25px;
  }

  .quote-applied-content {
    display: flex;
    width: auto;
  }

  .quote-applied-label {
    font-size: 14px;
    width: 60px;
    padding-right: 5px;
    line-height: 1.4;
    align-self: center;
  }
}
