.soundIcon {
  cursor: pointer;
  fill: #1f2525;
  height: 22px;
  left: 10px;
  position: absolute;
  top: 10px;
  width: 25px;
  z-index: 3;

  &__wave {
    opacity: 1;
  }

  &__stripe {
    opacity: 0;
  }

  &--muted {
    .soundIcon__wave {
      opacity: 0;
    }

    .soundIcon__stripe {
      opacity: 1;
    }
  }
}
