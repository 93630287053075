@import '../../../../../../styles/colors.scss';
@import '../../../../../../styles/base-containers.scss';

.contact {
  padding: 25px;
  font-family: OpenSans-SemiBold;
  background-color: white;
  color: black;

  @media (max-width: 767px) {
    width: calc(100% - 50px) !important;
  }

  .contact-title {
    font-size: 10px;
    color: lightslategray;
    display: block;
  }

  .contact-company-rep {
    font-family: OpenSans-Regular;
  }

  .contact-content {
    font-size: 18px;
    display: block;
  }

  .description {
    margin-top: 0px;
    margin-bottom: 20px;
    display: block;
    font-size: 15px;
    font-family: OpenSans-RegularItalic;
  }

  input {
    width: 50%;
    height: 40px;
    background: transparent;
    border: none;

    @media (max-width: 767px) {
      width: 100%;
    }

    &:disabled {
      color: #666;
      background-color: #E8E8E8;
    }
  }

  input.evaluation {
    &:disabled {
      background-color: transparent !important;
    }
  }
}
