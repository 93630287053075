@import 'styles/colors.scss';

.bid-pricing-root {
  width: 100%;
  display: flex;
  background-color: $white;

  .bid-pricing-content {
    flex-direction: column;
    padding: 20px;
  }
  .bid-pricing-title {
    color: $dark-grey;
  }

  .bid-pricing-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 20px;
  }

  .bid-pricing-label:last-of-type {
    padding-left: 10px;
  }

  .bid-pricing-label {
    color: $dark-grey;
    width: 50%;
    align-self: center;
    padding-right: 60px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
  }

  .bid-pricing-content {
    display: flex;
    width: 100%;
    padding-top: 25px;
  }

  .bid-pricing-content .bid-pricing-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0;
    padding-top: 20px;
  }

  .bid-pricing-content .bid-content-item {
    display: flex;
    width: 50%;
    justify-content: space-between;
    align-items: center;
  }
  .bid-pricing-content .bid-content-item:first-child {
    padding-right: 10px;
  }
  .bid-pricing-content .bid-content-item:last-child {
    padding-left: 10px;
  }

  .bid-pricing-content
    .bid-pricing-break-label
    .div-float-label
    input:placeholder-shown:not(:focus)
    + * {
    top: 0.7rem;
    width: 90%;
    line-height: 24px;
  }

  .bid-pricing-content .div-float-label {
    top: -0.5em;
    width: 100%;
    line-height: 12px;
  }

  .bid-pricing-content .div-float-label label {
    width: 65%;
  }
}

.daily-man-hours-root {
  width: 100%;
  display: flex;
  background-color: $grey-section;

  .daily-man-hours-title {
    color: $dark-grey;
  }

  .daily-man-hours-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 20px;
  }

  .daily-man-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    @media (max-width: 769px) {
      font-size: 13px;
    }
  }

  .daily-man-item {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
  }
}
