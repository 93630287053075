@import 'styles/colors.scss';

.checkbox-list-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 20px;
  justify-content: space-between;

  .checkbox-list-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    border-radius: 30px;
    border: 1px solid $medium-grey;
    margin-right: 20px;

    .checkbox-list-selected {
      width: 22px;
      height: 22px;
      border-radius: 22px;
      background-color: $secondary-color;
    }
  }
  .checkbox-list-additional {
    flex: 1;
  }
}
