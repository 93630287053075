@import '../../../../styles/colors.scss';

.search-modal-container {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .table-container {
    display: table-caption;
    height: 100%;
    border: 0;
  }
}

.loader-container {
  height: 100%;

  .loading-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    width: 400px;
    height: 400px;
    border-radius: 12px;

    p {
      color: $primary-color;
      font-size: 24px;
      padding-top: 48px;
    }
  }

  .loader-search {
    border: 16px solid #f3f3f3;
    /* Light grey */
    border-top: 16px solid $primary-color;
    /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spinnner 2s linear infinite;
  }
}

@keyframes spinnner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

table.table-container {
  // border-bottom: 0;

  td.td-title {
    word-break: break-all;
  }

  td.td-auto-save {
    min-width: 10%;
    max-width: 10%;
    text-align: right;
  }

  td.td-actions {
    min-width: 12%;
    max-width: 12%;
  }

  td.td-last-update {
    min-width: 28%;
    max-width: 28%;
  }
}

.search-input {
  width: 100%;
  margin: 24px 0;
  border-bottom: 1px solid $medium-grey;

  input {
    width: 100%;
    outline: 0;
    border: none;
    background-color: transparent;
  }
}

div.search-modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  justify-content: flex-start;
  background-color: white;
  // width: 50vw;
  // width: calc(100vw - 100px);
  // height: calc(100vh - 100px);
  width: 715px;
  height: 90%;
  overflow: hidden;
  padding: 25px 40px;

  h2.search-title {
    margin-bottom: 30px;
    color: #000;
    text-align: center;
  }

  p.search-message {
    margin-bottom: 50px;
    text-align: center;
    font-size: 1.2em;
  }

  div.buttons-wrapper {
    margin-top: 10px;
  }
  .button-close {
    position: absolute;
    top: 10px;
    right: 20px;
  }
}
