.pdf-modal-root {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.pdf-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  width: 87vw;
  min-height: 80%;
  overflow: hidden;

  .pdf-content {
    align-self: center;
  }

  .modal-close-button {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding-right: 50px;
    padding-top: 20px;
    min-height: fit-content;
  }

  .pdf-buttons {
    display: flex;
    justify-content: space-between;
    .button-secondary.square {
      background-image: none;
      background-color: #fff;
      width: 180px;
      border: 2px solid #216eab;
      &.border-black {
        color: #000;
        border: 2px solid #000;
      }
    }

    .disabled {
      opacity: 0.5;
    }
  }

  @media (max-width: 425px) {
    .pdf-external-box-mobile {
      max-width: 100%;
    }

    .react-pdf__Page__canvas {
      width: 100% !important;
      height: auto !important;
    }

    // .Toastify__toast-container {
    //   width: 50vw !important;
    //   left: initial !important;
    // }
  }
}
