@import './variables';

.calculator {
  display: flex;
  flex-direction: column;
  height: inherit;

  &__header {
    align-content: space-between;
    background-color: $air;
    display: inherit;
    flex-basis: 25%;
    flex-wrap: wrap;
    // padding: 10px;
    display: flex;
  }

  &__display {
    flex: 1;
    margin: 1vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    overflow-x: auto;

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }
    &::-webkit-scrollbar-button {
      width: 0px;
      height: 0px;
    }
    &::-webkit-scrollbar-thumb {
      background: #e1e1e1;
      border: 0px none #ffffff;
      border-radius: 50px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #ffffff;
    }
    &::-webkit-scrollbar-thumb:active {
      background: #000000;
    }
    &::-webkit-scrollbar-track {
      background: #666666;
      border: 0px none #ffffff;
      border-radius: 50px;
    }
    &::-webkit-scrollbar-track:hover {
      background: #666666;
    }
    &::-webkit-scrollbar-track:active {
      background: #333333;
    }
    &::-webkit-scrollbar-corner {
      background: transparent;
    }
  }

  &__backspace {
    width: 25%;
    height: 100%;

    button {
      height: 100%;
      width: 100%;
    }
  }

  &__body {
    background-color: $base-color;
    display: inherit;
    flex: 1 auto;
    flex-basis: 77%;
    flex-wrap: wrap;
  }

  &__history {
    font-size: 1.1rem;
    margin: 0;
    opacity: 0.6;
    overflow-x: hidden;
    position: relative;
    text-align: right;
    width: 89%;
  }

  &__result {
    font-size: 10vh;
    line-height: 10vh;
    margin: 0;
    padding: 0;
    text-align: right;
    width: 100%;
    height: 10vh;
  }
}
