@import '../../../styles/colors.scss';

.modal-background-inspection {
  // position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  // justify-content: center;
  justify-content: flex-start;

  .modal-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: white;
    // width: calc(100vw - 2px);
    width: 100%;
    height: 100%;
    // min-height: calc(100vh - 58px);
    // min-height: 100vh;
    // min-height: calc(100vh - 100px);
    // border-radius: 12px;
    border-radius: 0;
    overflow: hidden;
  }

  .modal-title-container {
    display: flex;
    // flex-direction: column;
    // justify-content: center;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    width: 100%;
    background-color: $primary-color;

    h3 {
      color: white;
      margin: 0px;
      margin-left: 25px;
    }

    div {
      margin: 0px;
      margin-right: 32px;
      color: white;

      span {
        margin-left: 5px;

        &.sort-button-clicked {
          color: $medium-grey;
        }
      }
    }
  }

  .buttons-row {
    display: flex;
    flex-direction: row;
    width: 250px;
    height: 60px;
    align-items: center;
    justify-content: space-between;

    p {
      display: block;
      width: 75%;
      color: white;
    }
    .button-secondary,
    .button {
      padding: 6px 0;
    }
  }
}

.inspection-report-menu-container {
  //display: flex;
  //flex-direction: column;
  //align-items: center;
  //justify-content: flex-start;
  //width: 100%;
  //height: 100vh;
  padding: 24px;
  padding-top: 12px;
  overflow-y: auto;
  width: calc(100% - 48px);
  height: 75%;
  padding-bottom: 0;

  .section-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    // max-height: 50vh;
    margin-top: 12px;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;

    .table-container {
      display: block;
    }

    .title-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: $grey-background;
      width: 100%;
      min-height: 56px;
      max-height: 56px;
      //min-height: 6vh;

      .content-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 96%;
        height: 100%;

        p {
          //color: $dark-grey;
          font-size: ‬22px;
          font-family: 'OpenSans-BoldItalic';
        }
      }
    }
  }
}

.table-container {
  //display: flex;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  border-bottom: 1px solid #9a9a9a;

  tbody {
    display: flex;
    flex-direction: column;
    width: 95%;
    //overflow-y: scroll;
    margin-top: 0;

    tr {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      //min-height: 50px;
      padding: 25px 0;
      height: auto;
      border-bottom-width: 1px;
      border-bottom-color: $medium-grey;
      border-bottom-style: solid;

      @media (max-width: 576px) {
        padding: 10px 0;
      }

      > td {
        @media (max-width: 576px) {
          font-size: 12px !important;

          &.td-rating {
            span {
              font-size: 12px !important;
            }
            p {
              margin: 0;
            }
          }
        }
      }

      .td-auto-save {
        min-width: 10%;
        max-width: 10%;
        text-align: right;
      }

      .td-title {
        display: flex;
        min-width: 23%;
        max-width: 23%;
        padding-left: 12px;
        height: 100%;
        align-items: center;
        justify-content: flex-start;
        color: $dark-grey;
        font-size: 1.125rem;
        font-family: 'OpenSans-SemiBold';
      }

      .td-rating {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        height: 100%;
        min-width: 25%;
        max-width: 25%;
        font-size: 16px;

        span {
          color: $dark-grey;
          font-size: 16px;
          font-family: 'OpenSans-SemiBold';
        }
        p {
          margin: 0;
        }
      }

      .td-last-update {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        min-width: 30%;
        max-width: 30%;
        font-size: 16px;

        @media (max-width: 768px) {
          min-width: 20% !important;
          max-width: 20% !important;
        }
      }

      .td-actions {
        display: flex;
        height: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-width: 10%;
        max-width: 10%;
        font-size: 12px;
        text-decoration: underline;

        @media (max-width: 768px) {
          min-width: 15% !important;
          max-width: 15% !important;
        }
      }
    }

    .load-more-wrapper {
      display: flex;
      justify-content: center;

      .button-secondary {
        background-image: none;
      }
    }
  }
}


.inspect-name-container {
  padding: 24px;
  padding-top: 12px;
  overflow-y: auto;
  overflow-x: hidden;
  width: calc(100% - 48px);
  padding-bottom: 0;

  .refresh-icon {
    cursor: pointer;
    position: absolute;
    right: 0;
  }
  h3 {
    color: #000000;
  }
}  