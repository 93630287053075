@import '../../../styles/colors.scss';

.add-photo-modal-root-div {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.add-photo-modal-content-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: white;
    width: 80vw;
    min-height: 50vh;
    border-radius: 12px;
    overflow: hidden;

    .add-photo-modal-title-container {
        display: flex;
        width: 100%;
        height: 7vh;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: $primary-color;


        h3 {
            color: white;
        }
    }

    .photo-text-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 10%;
        padding-top: 24px;

        .alert-text {
            max-width: 70%;
        }

        p {
            color: black;
            text-align: center;
        }

        span {
            font-family: 'OpenSans-SemiBold';
            color: black;
            text-align: center;
        }
    }



    .photos-list-container {
        display: flex;
        width: 80vw;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        overflow-x: scroll;
        margin-bottom: 42px;
        margin-top: 24px;

        .div-img-container {
            display: flex;
            height: 20vh;
            width: 16vh;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 20px;

            .div-img {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 16vh;
                width: 16vh;
                border-radius: 1vh;

                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;

                border-width: 1px;
                border-color: $medium-grey;
                border-style: solid;

            }

            .div-img-dismiss-container {
                display: flex;
                width: 15.5vh;
                align-items: flex-end;
                justify-content: flex-end;
                margin-top: 8px;

                span {
                    color: $dark-grey;
                    text-decoration: underline;
                }
            }
        }



        .add-photo-box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 16vh;
            width: 16vh;
            min-width: 16vh;
            margin: 20px;
            border-radius: 1vh;
            cursor: pointer;
            background-color: $light-grey;
            border-width: 1px;
            border-color: $medium-grey;
            border-style: solid;

            .add-photo-div {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                input[type="file"] {
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    width: 90%;
                    height: 42px;
                    opacity: 0;
                    position: absolute;
                    z-index: -1;
                }

                label {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }

                p {
                    margin: 0;
                    font-size: 48px;
                    color: $dark-grey;
                    margin-bottom: 12px;
                }

                span {
                    color: $dark-grey;
                    text-decoration: underline;
                }
            }
        }

    }
}

.add-photo-modal-done-button {
    position: relative;
    top: -24px;
}
