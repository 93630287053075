@import '../../../../../../styles/colors.scss';
@import '../../../../../../styles/base-containers.scss';

.evaluation {
  .suggestions-box {
    width: calc(100% - 25px);
    margin-bottom: 0;
    resize: none;
    outline: none;
    font-size: 16px;
    margin-top: 20px;
    border: none;
    padding-left: 20px;
    padding-top: 15px;
  }
  .evaluation-date {
    width: calc(40% - 15px) !important;
    margin-left: 15px;
    color: darkgray;
  }

  textarea {
    &:disabled {
      border: 1px solid silver;
    }
    font-family: 'OpenSans-SemiBold';
    font-size: 15px !important;
  }
}
