@import '../../../styles/colors.scss';

.loading-modal-hide {
  display: none;

  z-index: 999999;
}

.loading-modal-root-div {
  z-index: 999999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;


  &.low-opacity {
    background-color: rgba(0, 0, 0, 0) !important;
  }
}

.loading-modal-content-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 400px;
  height: 400px;
  border-radius: 12px;

  p {
    color: $primary-color;
    font-size: 24px;
    padding-top: 48px;
  }

}

.loader {
  border: 16px solid #f3f3f3;
  /* Light grey */
  border-top: 16px solid $primary-color;
  /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
