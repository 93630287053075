@import 'fonts';
@import './variables';

.react-calculator {
  * {
    box-sizing: border-box;
  }

  button {
    font-family: inherit;
    margin: 0;
  }

  a {
    color: $air;
  }
}
