@import 'styles/colors.scss';
.footer-root {
  box-sizing: border-box;
  // position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  height: 72px;
  background-color: $white;
  align-items: center;
  justify-content: center;
  border-top-width: 1px;
  border-top-style: solid;
  border-color: grey;
  width: 100%;
}

.footer-content {
  position: sticky;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 24px;
}


.footer-content-buttons {
  display: flex;
  gap: 20px;
  @media (max-width: 1024px) {
    button {
      font-size: 18px;
    }
  }
}