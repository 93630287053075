@import 'styles/colors.scss';
.performance-footer-root {
  // position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  height: 72px;
  background-color: $white;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: none !important;
  margin-top: 10px;
  @media (max-width: 767px) {
    bottom: -85px !important;
    position: absolute;
  }

  .footer-content {
    position: sticky;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end !important;
    padding-left: 24px;
    padding-right: 40px;
  }
}


