@import '../../../../../../styles/colors.scss';
@import '../../../../../../styles/base-containers.scss';
@import '../../../../../../styles/input.scss';

.second-form-container {
  @extend %base-padding-horizontal;
  @extend %base-padding-vertical;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: $white;

  .title-row-div {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    h2 {
      color: $dark-grey;
      font-size: 22px;
      margin: 0px;
    }

    select {
      width: 24vw;
      height: 42px;
      border-color: $medium-grey;
      border-width: 1px;
      border-style: solid;
      color: $dark-grey;
      font-family: 'OpenSans-Regular';
      background: url('../../../../../../assets/icons/arrow-down.png') no-repeat
        right;
      background-size: contain;
    }
  }

  .second-form-content-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .has-float-label {
      width: 100%;

      label {
        color: $dark-grey;
      }
    }

    input:disabled {
      opacity: 0.5;
    }

    .is-invalid {
      input {
        border-bottom-color: red;
      }

      label, input::placeholder {
        color: red;
      }

      textarea{
        border-bottom: 1px solid #ff000099 !important;
      }
    }
  }

  .second-form-rows-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 12px;

    .address-field-container {
      display: flex;
      width: 55%;
      align-items: flex-start;
      justify-content: flex-start;
    }

    .suite-field-container {
      display: flex;
      width: 40%;
      align-items: flex-start;
      justify-content: flex-start;
    }

    .postal-code-field-container {
      display: flex;
      width: 30%;
      align-items: flex-start;
      justify-content: flex-start;

      input {
        text-transform: uppercase;
      }
    }

    .city-field-container {
      display: flex;
      width: 30%;
      align-items: flex-start;
      justify-content: flex-start;
    }

    .state-field-container {
      display: flex;
      width: 30%;
      align-items: flex-start;
      justify-content: flex-start;
    }

    .days-cleaned-container {
      display: flex;
      width: 60%;
      align-items: center;
      justify-content: center;
    }

    .time-window-container {
      display: flex;
      width: 35%;
      align-items: flex-start;
      justify-content: flex-start;
    }

    .walk-thru-contact-name-container {
      display: flex;
      width: 55%;
      align-items: flex-start;
      justify-content: flex-start;
    }

    .walk-thru-contact-phone-container {
      display: flex;
      width: 40%;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  input {
    background-color: transparent;
    width: 100%;
    color: black;
    border-width: 0px;
    margin-top: 12px;
    border-radius: 0;
    border-bottom-color: grey;
    border-bottom-width: 1px;
    border-style: solid;

    @include placeholder {
      color: grey;
      opacity: 0.5;
    }
  }

  input[type='time'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 90%;
    color: black;
    border-width: 0px;
    border-radius: 0;
  }

  input[type='time']:not(.has-value):before {
    color: grey;
    opacity: 0.5;
    content: attr(placeholder);
  }

  .days-cleaned-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 52px;
    background-color: $white;
    width: 100%;
    margin-top: 12px;

    .div-field {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      width: 25%;
    }

    .div-buttons {
      width: 75%;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      .button-unselected-day {
        border-right-width: 0;
      }
    }

    p {
      color: $dark-grey;
      font-family: 'OpenSans-SemiBold';
      font-size: 18px;
      width: 90%;
      padding-left: 6px;
      padding-right: 15px;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      border-width: 0.1;
      border-color: grey;
      color: $dark-grey;
      background-color: white;
      font-size: 14px;
      height: 100%;
      width: calc(100% / 7);
    }

    .button-selected {
      background-color: $primary-dark-color !important;
      border-color: $primary-dark-color;
      color: $white;
    }

    button:disabled {
      background-color: #ddd;
      // opacity: 0.7;
    }
  }
}

// @media only screen and (min-width: 1201px) {
//     .second-form-container {
//         width: 100%;

//         .second-form-content-container {
//             display: flex;
//             max-width: 96%;
//             flex-direction: column;
//             align-items: center;
//             justify-content: flex-start;
//         }
//     }

//     .second-input-form-row-items {
//         display: flex;
//         width: 100%;
//         flex-direction: row;
//         align-items: center;
//         justify-content: space-around;

//     }

// }
