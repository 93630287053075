@import 'styles/colors.scss';

.room-inventory-root {
  width: 100%;
  display: flex;
  background-color: $grey-section;

  .room-inventory-title {
    padding-left: 20px;
    color: $dark-grey;
  }

  .room-inventory-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 20px;
    padding-left: 0px;
  }
  .delete-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 0;
    text-decoration: underline;
    cursor: pointer;
    width: 40px;
  }
  .lxw-input {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    background-color: #fff;
  }

  .add-room-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .label-room {
      margin-right: 10px;
    }

    .add-button {
      width: 150px;
      height: 35px;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      color: $white;
      margin-right: 10px;
      font-size: 16px;

      &:disabled {
        opacity: 0.7;
      }
    }

    .light {
      background-color: $primary-light-color;
    }

    .medium {
      background-color: $primary-dark-color;
    }

    .dark {
      background-color: $primary-color;
    }
  }
}

.fixtures-tooltip-wrapper {
  display: flex;
  justify-content: center;
  position: relative;
}

.fixtures-tooltip-wrapper .fixtures-button-tooltip {
  border: 1px solid #216eab;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin-left: 10px;
  margin-right: 0;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  // position: absolute;
  right: -10px;
  margin-right: -32px;

  @media (max-width: 768px) {
    margin-left: 12px;
    margin-right: -34px;
  }
}
