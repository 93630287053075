.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .modal-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: white;
    width: 80vw;
    min-height: 50vh;
    border-radius: 12px;
    overflow: hidden;
  }

  .modal-title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 8vh;
    width: 100%;
    background-color: $primary-color;

    h3 {
      color: white;
      margin: 0px;
    }

    span {
      margin: 0px;
      color: white;
    }
  }
}

.modal-done-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  position: relative;
}

.modal-background-inspection .modal-done-button {
  margin-top: -100px;
}
