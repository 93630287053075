@import 'styles/colors.scss';

.service-provider-root {
  width: 100%;
  display: flex;
  background-color: $white;

  .service-provider-title {
    color: $dark-grey;
    margin-bottom: 15px;
  }

  .service-provider-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 20px;
  }

  .service-provider-cost {
    display: flex;
    border: 1px solid #999;
    align-items: center;
    padding-left: 10px;
    height: 40px;
    align-self: flex-end;
  }
}
