@import 'styles/colors.scss';

.supplies-root {
  width: 100%;
  display: flex;
  background-color: $white;

  .supplies-title {
    color: $dark-grey;
    margin-bottom: 15px;
  }
  
  .supply-label {
    padding: 0;
    border: 0;
    background: none;
    cursor: pointer;
    display: inline-flex;
    text-decoration: underline;
    color: #0645AD;
    font-family: 'OpenSans-Regular';
  }

  .supplies-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 20px;
  }

  .supplies-cost {
    display: flex;
    border: 1px solid #999;
    align-items: center;
    padding-left: 10px;
    height: 40px;
    align-self: flex-end;

    input {
      margin-left: 10px;
    }
  }
}
