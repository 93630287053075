@import '../../../styles/colors.scss';


.input-autocomplete {
  outline: 0;
  border-width: 0 0 2px;
}

div.save-as-backdrop {
  background-color: transparent;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
}

.suggestions {
  display: flex;
  flex-direction: column;
  position: relative;
  // top: 8.8vh;
  // left: -23.5vw;

  min-width: 39vw;
  //max-width: 39vw;
  max-height: 12vh;
  // height: 12vh;

  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background-color: white;

  .row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 12px;
    width: calc(100% - 12px);
    height: 42px;
    // border-bottom: 1px solid #999;
    font-size: 1rem;
  }
}

.btn-dropdown{
  color: transparent;
  display: inline-block;
  width: 52px;
  height: 52px;
  background-color: transparent;
  background-image: url(arrow.svg);
  background-position: center;
  background-size: 25px;
  background-repeat: no-repeat;
  background-color: #eeeeee;
  border: none;
  cursor: pointer;
  vertical-align: top;
  transform: rotate(90deg);
  &.btn-dropdown-open{
    transform: rotate(-90deg);
  }
  & ~ input{
    width: calc(100% - 52px);
  }
}
