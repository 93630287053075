@import "styles/colors.scss";

.floor-type-totals-root {
  width: 100%;
  display: flex;
  background-color: $grey-section;

  .floor-type-totals-title {
    color: $dark-grey;
  }

  .floor-type-totals-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 20px;
  }

  .floor-totals-list {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 10px;
    padding-bottom: 0;
  }

  .floor-total-item {
    width: 45%;
    margin-right: 5%;
  }
}
