/* Style the accordion section */
.accordion__section {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  .disabled-overlay {
    position: absolute;
    background-color: #f5f5f5;
    width: 100%;
    height: 50px;
    opacity: 0.6;
    border-radius: 12px;
  }
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  cursor: pointer;
  padding: 18px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
}

.accordion-centered {
  justify-content: center;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.accordion:hover,
.active {
  // background-color: #ccc;
}

/* Style the accordion content title */
.accordion__title {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 14px;
}

/* Style the accordion chevron icon */
.accordion__icon {
  transform: rotate(90deg);
  margin-left: auto;
  transition: transform 0.6s ease;
  padding-top: 5px;
}

/* Style to rotate icon when state is active */
.rotate {
  transform: rotate(-90deg);
}

/* Style the triangle chevron icon */
.triangle__icon {
  transform: rotate(0deg);
  margin-left: auto;
  transition: transform 0.6s ease;
}

/* Style to rotate icon when state is active */
.rotate__more {
  transform: rotate(-180deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  overflow: hidden;
  // transition: max-height 0.6s ease;
}

/* Style the accordion content text */
.accordion__text {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 14px;
  padding: 18px;
}

/*Style for the case accordion is hidden and there's a button*/

.accordion-hidden {
  display: flex;
  flex-direction: column;
  button {
    height: 35px;
  }
}
