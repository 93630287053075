@import '../../../../styles/colors.scss';
@import '../../../../styles/base-containers.scss';
@import '../../../../styles/input.scss';

.report-content-screen {
  font-size: 1rem;
}

.preview-photo-root-div {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.report-header {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  @media (max-width: 600px) {
    flex-wrap: wrap;
    justify-content: space-around;
    .button.btn-back,
    .back-button-container {
      width: 45%;
      margin: 15px 0;
      button {
        width: 100%;
      }
    }
    @media (max-width: 400px) {
      .button {
        padding-left: 6px;
        &.btn-back:after,
        &.btn-print:after {
          display: none;
        }
      }
    }
  }

  img.logo {
    height: 100px;
    max-width: 100%;
    margin: auto;
    @media (max-width: 600px) {
      margin: 0 14%;
      display: block;
      order: -1;
    }
  }

  .relative {
    position: relative;
  }

  .bubble {
    position: absolute;
    top: -15vh;
    //width: 25vw;
    height: 14vh;
    padding: 0px;
    background: #ffffff;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    border: $medium-grey solid 2px;
    font-size: 1em;
    z-index: 10;

    // right: 8%;

    &.go-down {
      left: 30px;
      top: 60px;
      &:after {
        content: '';
        position: absolute;
        bottom: calc(100% + 1px);
        left: 35px;
        width: 0;
        border-bottom: 10px solid #9a9a9a;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding-left: 10px;
      padding-right: 10px;

      color: $primary-dark-color;
      font-size: 1em;
      font-family: 'OpenSans-SemiBoldItalic';

      .first-row {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 50%;
        width: 100%;
        cursor: pointer;
      }

      .first-row.underlined {
        border-bottom: 2px solid $medium-grey;
      }

      .second-row {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 50%;
        width: 100%;

        cursor: pointer;
      }
    }
  }
}

.report-container-head {
  color: $dark-grey;
  background-color: $grey-background;
  padding: 20px 20px 50px 20px;
  width: calc(100% - 40px);
  @media (max-width: 600px) {
    width: calc(100% - 20px);
    padding: 20px 10px 50px 10px;
  }

  h1 {
    color: $dark-grey;
    text-align: center;
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }

  .conducted-by {
    margin: auto;
    width: 50%;
    text-align: center;
    @media (max-width: 600px) {
      width: 100%;
    }
  }

  .head-form-content {
    padding: 20px 0;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    text-align: right;
    .head-form-content-inner {
      display: inline-block;
      width: calc(100% - 55px);
      @media (max-width: 767px) {
        width: calc(100% - 35px);
      }
      @media (max-width: 600px) {
        width: 100%;
      }

      &.menu-open {
        width: 80%;
        @media (max-width: 600px) {
          width: 100%;
        }
      }
    }

    label {
      padding-left: 0;
      width: calc(33.33% - 10px);
      box-sizing: border-box;
      display: inline-block;
      @media (max-width: 400px) {
        width: 100%;
      }
      & + label {
        margin-left: 15px;
        @media (max-width: 400px) {
          margin-left: 0;
          margin-top: 15px;
        }
      }

      input,
      input:disabled,
      input:hover,
      input:focus {
        width: 100%;
        display: block;
        background-color: #fff;
        border: initial;
        opacity: 1;
      }
    }
  }
}

.report-container-body {
  padding: 0 20px;
  margin-top: -50px;
  width: calc(100% - 40px);
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  @media (max-width: 600px) {
    flex-wrap: wrap;
    max-width: 100%;
    padding: 0;
    margin-top: -35px;
    width: calc(100% - 20px);
  }
  .report-menu {
    margin-top: 120px;
    display: inline-block;
    @media (max-width: 767px) {
      margin-left: -20px;
    }
    &.menu-open {
      min-width: 20%;
      @media (max-width: 767px) {
        min-width: calc(20% + 20px);
      }
      .btn-menu {
        transform: rotate(180deg);
      }
      ul {
        li {
          div {
            display: block;
            word-break: break-word;
          }
        }
      }
    }
    .btn-menu,
    .btn-menu-back {
      width: 40px;
      height: 40px;
      margin-top: -50px;
      margin-left: 5px;
      background-color: transparent;
      background-image: url(arrow.svg);
      background-position: center;
      background-size: 25px;
      background-repeat: no-repeat;
      border: none;
      position: absolute;
      cursor: pointer;
    }

    .btn-menu-back {
      margin-top: 0;
      transform: rotate(180deg);
      border-radius: 50px;
      background-color: $primary-light-color;
    }

    ul {
      list-style: none;
      margin-top: 0;
      padding-left: 0;

      li {
        line-height: 1.3;
        min-height: 40px;
        // padding: 5px 5px 5px 40px;
        padding: 0px 10px 0px 40px;
        box-sizing: border-box;
        margin: 15px 0;
        cursor: pointer;
        border-left: 3px solid transparent;
        display: flex;
        align-items: center;

        &hover {
          font-size: 1.2rem;
        }
        &:first-of-type {
          margin: 5px 0 20px 0px;
        }

        &.active {
          border-left: 3px solid $primary-light-color;
        }
        div {
          display: none;
        }

        .menu-icon {
          max-width: 30px;
          //position: absolute;
          padding-right: 10px;
          margin-left: -32px;
          margin-top: auto;
          margin-bottom: auto;
          align-self: center;
        }
      }
    }

    &.stick {
      ul {
        position: -webkit-sticky;
        position: sticky;
        top: 60px;
      }
    }
    &::-webkit-scrollbar {
      display: none;
      background: transparent;
    }

    @media (max-width: 600px) {
      //min-width: 100%;
      position: -webkit-sticky;
      position: sticky;
      top: 0px;
      background-color: #fff;
      padding: 0 0;
      margin: 0 -10px 0px -10px;
      box-shadow: inset -35px 0px 25px -25px #e6e6e6,
        inset 35px 0px 25px -25px #e6e6e6, rgba(63, 63, 63, 0.3) 0px 10px 35px;
      min-width: 100vw;
      overflow-x: scroll;
      z-index: 1;

      .report-menu-relative {
        position: relative;
      }

      ul {
        top: 5px;
        margin: 0;
        height: 40px;
        width: max-content;
        padding: 0 5px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        li,
        li:first-of-type {
          margin: 0;
          float: left;
          padding-bottom: 5px;
          padding-top: 5px;
          border-left: none;
          border-bottom: 3px solid transparent;
          &.active {
            border-left: none;
            border-bottom: 3px solid #216eab;
          }

          div {
            display: inline-block;
          }
        }
      }
      &.stick {
        ul {
          top: 5px;
        }
      }
      .btn-menu {
        display: none;
      }
    }
  }

  .report-container-content {
    // width: 80%;
    display: inline-block;
    .box-container {
      overflow: visible;
      @media (max-width: 600px) {
        padding-top: 50px;
        margin-top: -15px;
      }
    }

    .client-info-root {
      background-color: #ffffff;
      margin-top: 50px;
    }

    input {
      background-color: #ffffff;
    }

    .box {
      color: $dark-grey;
      box-shadow: rgba($color: $dark-grey, $alpha: 0.3) 0px 10px 35px;
      border-radius: 8px;
      margin-bottom: 50px;
      @media (max-width: 600px) {
        margin-bottom: 30px;
      }

      h3 {
        font-size: 1.625rem;
        line-height: 1.3;
      }

      h2 {
        font-size: 1.85rem;
        line-height: 1.3;
      }

      .box-title {
        color: #fff;
        background-color: $primary-light-color;
        min-height: 50px;
        padding: 5px 20px;
        margin-top: 0;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        @media (max-width: 600px) {
          flex-wrap: wrap;
          padding-bottom: 10px;
        }

        h2,
        h3 {
          text-transform: none;
          font-weight: normal;
          margin: auto 0;
          @media (max-width: 600px) {
            margin-bottom: 10px;
            width: 100%;
          }
        }

        span {
          margin-left: 30px;
          @media (max-width: 600px) {
            margin-left: 0;
          }
        }

        .average-rating {
          margin-left: auto;

          .average-rating-value {
            font-size: 30px;
            font-weight: bold;
            vertical-align: sub;
            margin-left: 5px;
          }
        }
      }

      .report-box-body {
        padding: 20px 24px;

        h3 {
          color: $dark-grey;
          margin-bottom: 20px;
        }

        .notes-textarea {
          font-size: 16px;
          font-family: 'OpenSans-Regular';
          width: 100%;
          min-height: 50px;
          max-height: 300px;
          padding: 15px;
          border: 1px solid $medium-grey;
          margin-bottom: 10px;
          box-sizing: border-box;
          overflow-y: scroll;
          white-space: pre-line; // will break lines where they break in code, but extra white space is still stripped - https://css-tricks.com/almanac/properties/w/whitespace/
          word-break: break-word;
        }

        .right-box-btn {
          font-size: 16px;
          font-family: 'OpenSans-Regular';
          text-align: right;

          button {
            color: #fff;
            font-size: 16px;
            background-color: $medium-grey;
            padding: 10px 35px;
            border: none;
            border-radius: 6px;
            min-height: 50px;
          }
        }

        .rating-item-list {
          margin: 0 -15px;
        }

        .rating-item {
          border: 1px solid $medium-grey;
          width: calc(33.33% - 30px);
          display: inline-flex;
          min-height: 50px;
          margin: 0 15px 20px 15px;
          box-sizing: border-box;
          vertical-align: top;

          @media (max-width: 991px) {
            width: calc(50% - 30px);
          }

          @media (max-width: 500px) {
            width: calc(100% - 30px);
          }

          .rating-item-main {
            padding: 5px 20px;
            display: flex;
            align-items: center;
          }

          .rating-item-score {
            font-size: 26px;
            font-weight: 600;
            margin-left: auto;
            padding: 5px;
            background-color: $grey-background;
            min-width: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
          }
        }

        .report-photo-list {
          margin: 0 -15px;

          h3 {
            font-size: 1.625rem;
            font-weight: normal;
            color: $dark-grey;
            text-transform: none;
            border-top: 1px solid $medium-grey;
            margin: 20px 15px;
          }

          .report-photo-item {
            width: calc(33.33% - 30px);
            margin: 0 15px 20px 15px;
            margin-bottom: 20px;
            display: inline-block;
            vertical-align: text-top;

            @media (max-width: 991px) {
              width: calc(50% - 30px);
            }

            @media (max-width: 500px) {
              width: calc(100% - 30px);
            }

            .report-photo-item-img {
              width: 100%;
              height: 180px;
              box-sizing: border-box;
              border-radius: 8px;
              border: 1px solid $medium-grey;
              overflow: hidden;

              img {
                width: 100%;
                height: auto;
                min-height: 100%;
              }
            }
          }
        }
      }

      .report-container-form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        label {
          color: $dark-grey;
        }

        input,
        input:disabled,
        input:hover {
          border-width: 0 0 2px 0;
          border-color: $grey-background;
          width: 100%;
          opacity: 1;
        }

        .has-float-label {
          width: 100%;
          display: inline-block;
        }

        .days-cleaned-container {
          .div-field {
            width: 25%;
            display: inline-block;
            padding-right: 20px;
            box-sizing: border-box;
            @media (max-width: 400px) {
              width: 100%;
              p {
                margin-bottom: 0;
              }
            }
          }

          .div-buttons {
            width: 75%;
            display: inline-block;
            vertical-align: text-bottom;
            @media (max-width: 400px) {
              width: 100%;
              margin-bottom: 15px;
            }
            button {
              width: calc(100% / 7);
              height: 52px;
              padding: 0;
              margin-top: 16px;
              border: 1px solid $medium-grey;
              background-color: #fff;

              &:first-child {
                border-radius: 6px 0 0 6px;
              }

              &:last-child {
                border-radius: 0 6px 6px 0;
              }

              &.button-selected {
                color: #fff;
                background-color: $primary-light-color;
                border-color: $primary-light-color;
              }

              & + button {
                border-left: none;
              }
            }
          }
        }

        .w-50 {
          width: calc(50% - 20px);
        }

        .w-33 {
          width: calc(33.33% - 26.66px);
        }

        .w-66 {
          width: calc(66.66% - 13.33px);
        }

        .w-40 {
          width: calc(40% - 20px);
        }
        @media (max-width: 600px) {
          .w-50,
          .w-33,
          .w-66,
          .w-40 {
            width: 100%;
          }
        }
      }
    }

    .areas-header-container {
      margin-bottom: 50px;
      @media (max-width: 600px) {
        margin-bottom: 0;
      }

      .title-container {
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 10px;
        @media (max-width: 600px) {
          flex-wrap: wrap;
          padding-right: 10px;
        }
        @media (max-width: 400px) {
          flex-direction: column;
        }
        h2 {
          color: $dark-grey;
          margin: 0;
        }

        .average-rating {
          font-size: 1.625rem;
          //margin-left: auto;
          margin-right: 20px;
          @media (max-width: 400px) {
            margin-right: 0;
            padding-top: 10px;
          }

          .average-rating-value {
            font-size: 30px;
            font-weight: bold;
            margin-left: 5px;
          }
        }
      }

      .tabs-rating-container {
        .tab {
          height: 50px;
          p {
            font-size: 13px;
          }
        }
        @media (max-width: 500px) {
          flex-direction: column;
          .tab {
            height: 35px;
          }
        }
      }
    }
  }
}

.btn-back {
  padding-left: 30px;
  // background-image: url(button-background-yellow.png);
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    background-image: url(back-icon.png);
    background-size: contain;
    background-repeat: no-repeat;
    width: 25px;
    height: 25px;
    margin-left: -115px;
  }
}

.btn-back::before {
  content: '';
  position: absolute;
  width: 100%;
  max-width: 185px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7a11d;
  border: 0 none;
  border-radius: 0;
  padding: 0;
  transform: skew(30deg);
  z-index: -1;
  // margin-top: -13px;
  // margin-left: -6.5px;
  left: 3px;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.btn-back.square::before {
  transform: skew(0);
  max-width: 185px;
  height: 54px;
}

.btn-print {
  padding-left: 45px;
  padding-right: 6px;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    background-image: url(print-icon.png);
    background-size: contain;
    background-repeat: no-repeat;
    width: 25px;
    height: 25px;
    margin-left: -115px;
  }
}

.back-to-top {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  color: $primary-light-color;
  text-decoration: underline;
  span {
    cursor: pointer;
  }
}

.report-root-div {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: auto;
}
