div.action-alert-modal-backdrop {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;

  div.action-alert-modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    width: 50vw;
    overflow: hidden;
    padding: 25px 25px;

    @media (max-width: 768px) {
      width: 75vw;
    }

    @media (max-width: 600px) {
      width: 90vw;
      padding: 25px 10px;
    }

    h2.alert-title {
      margin-bottom: 30px;
      color: #000;
      text-align: center;
    }

    .alert-message-container {
      max-height: 60vh;
      overflow-y: auto;
      width: 100%;
      margin-bottom: 25px;
    }

    p.alert-message {
      text-align: center;
      font-size: 1.2em;

      span.alert-header {
        font-weight: bold;
      }
    }

    div.buttons-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      margin: 0 -20px;
      .button-secondary,
      .button {
        width: 185px;
        margin-bottom: 10px;
      }

      @media (max-width: 466px) {
        flex-direction: column;

        button {
          margin: 0;
        }

        button:last-child {
          margin-top: 10px;
        }
      }
    }

    span.required-field {
      color: red;
      font-weight: bold;
    }
  }
}
