@import '../../../styles/colors.scss';
@import '../../../styles/input.scss';

.input-label-content {
  width: 100%;

  input {
    background-color: transparent;
    width: 100%;
    color: black;
    border-width: 0px;
    margin-top: 12px;
    border-radius: 0;
    border-bottom-color: grey;
    border-bottom-width: 1px;
    border-style: solid;

    &:disabled {
      color: #666;
      background-color: #e8e8e8;
    }

    @include placeholder {
      color: grey;
      opacity: 0.5;
    }
  }

  input[type='time'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 90%;
    color: black;
    border-width: 0px;
    border-radius: 0;
  }

      /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance:textfield;
    }

  input[type='time']:not(.has-value):before {
    color: grey;
    opacity: 0.5;
    content: attr(placeholder);
  }

  label {
    color: $dark-grey;
  }
  .float-input-center {
    text-align: center;
  }
}