@import 'styles/colors.scss';

.cleaning-specification-root {
  width: 95%;
  display: flex;
  justify-content: center;
  background-color: $white;
  margin: 20px;

  .cleaning-specification-content {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 20px;
    border: 1px solid $medium-grey;

    .cleaning-specification-title {
      color: $dark-grey;
    }
  }
  .cleaning-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 20px;
  }
  .cleaning-label {
    color: $dark-grey;
    align-self: center;
    padding-right: 60px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    @media (max-width: 768px) {
      padding-right: 30px;
    }
  }

  .additional-content-frequency {
    margin-top: 15px;
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
  }

  .additional-frequency-button {
    width: 50%;
    align-self: center;
    background-color: $primary-light-color;
    text-decoration: underline;
    color: $white;
    padding: 10px;
    font-weight: bold;
    font-size: 14px;
    border-radius: 6px;
    border: none;
  }

  .disabled {
    opacity: 0.7;
  }

  .accordion-content {
    display: flex;

    & > div > div > div > svg {
      display: none;
    }
  }

  .cleaning-specification-button-tooltip {
    border: 1px solid #216eab;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin-left: 10px;
    margin-right: 0;
    height: 20px;
    width: 20px;
    border-radius: 20px;
    // position: absolute;
    right: -10px;
    margin-right: 10px;

    @media (max-width: 768px) {
      margin-left: 12px;
      margin-right: -34px;
    }
  }
}
