@import '../../../../styles/colors.scss';
@import '../../../../styles/base-containers.scss';
@import '../../../../styles/input.scss';

.inspection-root-div {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: auto;
  margin: auto;
}

.inspection-content-screen {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}

@media only screen and (min-width: 1201px) {
  .inspection-root-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    width: 98vw;
    height: auto;
    margin: auto;
  }

  .inspection-content-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 1200px;
    height: auto;
    overflow: hidden;
    overflow-x: hidden;
    border-width: 1px;
    border-color: $primary-color;
    border-style: solid;
  }
}

.inspection-content-screen .client-info-content {
  background: #ffffff;
}

.toolbar {
  background-color: $primary-color;
}

.first-input-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: auto;
}

.second-input-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: auto;
}

.third-input-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: auto;
}

.section-division {
  @extend %base-padding-horizontal;
  //height: 5vh;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;

  h2 {
    color: $dark-grey;
    font-size: 22px;
    margin: 0px;
  }

  p {
    margin-left: 12px;
  }
}

select:disabled {
  // background-color: #ddd !important;
  opacity: 0.7;
}

@media only screen and (min-width: 1201px) {
  .section-division {
    width: 100%;
    margin-left: 48px;
  }
}
