@font-face {
  font-family: 'geosanslightregular';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/geosanslight.woff2') format('woff2'), url('../fonts/geosanslight.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'rounded_eleganceregular';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/rounded_elegance.woff2') format('woff2'), url('../fonts/rounded_elegance.woff') format('woff');
  font-display: swap;
}
