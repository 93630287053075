@import 'styles/colors.scss';

.bid-information-root {
  width: 100%;
  display: flex;
  background-color: $primary-light-color;
  // margin-bottom: 72px;

  .react-datepicker-wrapper {
    width: 100% !important;

    input {
      height: 42px !important;
    }

    .expiration-date {
      border-color: white;
    }
  }

  .bid-information-title {
    color: $white;
  }

  .bid-information-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 20px;
  }

  .information-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .information-row-input {
    display: flex;
    width: 100%;
    background-color: $white;
    align-items: center;
  }

  .information-row-label {
    padding: 10px;
    padding-right: 0px;
  }

  .div-float-label input,
  .div-float-label label {
    color: white;
  }

  .div-float-label input:disabled,
  .div-float-label input:disabled + label {
    color: black;
    border: none;
  }

  .div-float-label input:disabled {
    margin-top: 4px;
  }

  .div-float-label input:disabled + label {
    margin-left: 5px;
  }

  .button-date-picker {
    width: 48%;
  }

  .button-date-picker input {
    text-align: center;
  }
  .button-date-picker input::after {
  }
}

.beta-optin-button-tooltip {
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin-right: 10px;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  margin-left: 20px;
}
