@import '../../../../styles/colors.scss';

 @mixin float-label-container {
  display: block;
  position: relative;
}

@mixin float-label {
  label, > span {
    position: absolute;
    left: 0;
    top: 0;
    cursor: text;
    font-size: .75rem;
    padding-left: 0;
    opacity: 1;
    transition: all .2s;
    @content;
  }
}

@mixin float-label-input {
  &::placeholder {
    transition: all .2s;
  }
  &:placeholder-shown:not(:focus)::placeholder {
      opacity: 0;
  }
}

@mixin float-label-scaled {
  &:placeholder-shown:not(:focus) + * {
      font-size: 1.125rem;
      opacity: .5;
      @content;
  }
}


.has-float-label {
  @include float-label-container;

  @include float-label;


  select {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
  }

  input, select {
    margin-top: 12px;
    @include float-label-input;
    font-size: inherit;
    margin-bottom: 2px;

    @include float-label-scaled {
      top: 1.7rem;
      padding-left: 8px;
    }

    &:focus {
      outline: none;
      border-bottom: 2px solid $secondary-color;
    }
  }

  select {
    padding-right: 1em;
    background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right .5em bottom .25em;
    background-size: 8px 10px;
  }
}
