@import 'styles/colors.scss';
@import 'styles/base-containers.scss';
@import 'styles/input.scss';

.bid-header {
  * {
    box-sizing: border-box;
  }
}

.check-connection-message-wrapper {
  position: absolute;
  width: 100%;
}

.check-connection-message {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f30b0b;
  position: fixed;
  top: 0;
  flex-direction: column;
  z-index: 99999;
  padding: 5px 0;

  @media (min-width: 1108px) {
    padding: 15px 0;
  }

  width: 100%;
  max-width: 1200px;

  .title {
    text-align: center;
    color: black;
    font-size: 14px;
  }

  .message {
    text-align: center;
    color: white;
    width: 95%;
    font-size: 14px;
  }
}

.bid-space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background-color: #f7a11d;
  @extend %base-padding-horizontal;
}

.date-status {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
  text-align: left;

  > div {
    display: flex;
  }
  .date-status-label {
    color: $primary-light-color;
    padding-right: 5px;
  }
}

.bid-form-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .bid-form-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 25px;

    .input-bid {
      width: 100%;
      margin-right: 5px;
    }

    .react-datepicker-wrapper {
      width: 100%;
    }
  }
}

input.input-bid {
  text-transform: inherit;
  &:disabled {
    color: #666;
    background-color: #E8E8E8;
  }
}

.bid-input-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: $white;

  input {
    &:focus {
      outline: 0 none;
    }
  }
}

.bid-input-label {
  font-weight: bold;
  padding-left: 5px;
}

input.bid-input-mastercity {
  background-color: #E8E8E8 !important;
  border: 0;
  width: 287px;
}

.input-header {
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  background-color: #fff;
  color: #000;
  border-radius: 0;
  border-color: $secondary-color;
  border-width: 3px;
  border-style: solid;

  @include placeholder {
    color: #3f3f3f;
    opacity: 0.5;
  }
}

.input-header[type='date'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  background-color: #fff;
  height: 52px;
  color: #000;
  font-size: 16px;
  border-radius: 0;
  border-color: $secondary-color;
  border-width: 3px;
  border-style: solid;
}

.input-header[type='date']:not(.has-value) {
  &:before {
    color: white;
    content: attr(placeholder);
  }
}

.input-header[type='date']::-ms-clear {
  display: none;
}

.input-proposal-datepicker {
  border-top: none;
  border-left: none;
  border-right: none;
}

.input-proposal-full-width {
  width: 100%;
  text-align: center;
}

.proposal-date-picker .react-datepicker-wrapper {
  width: 95%;
}

@media (max-width: 768px) {
  .proposal-date-picker {
    align-items: center;
  }
  .proposal-date-picker .react-datepicker-wrapper {
    width: 95%;
  }
}

.pid-tooltip {
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  // margin-right: 10px;
  height: 20px;
  width: 20px;
  border-radius: 20px;
}

div.is-invalid {
  border-color: #ff000099 !important;
  input, input::placeholder {
    color: red !important;
  }

  textarea{
    border-bottom: 1px solid #ff000099 !important;
  }

  svg {
    color: red !important;
  }
}

.is-invalid {
  color: red;

  input,
  select,
  div {
    border-color: #ff000099 !important;
  }

  div {
    input, input::placeholder {
      color: red !important;
    }
  }

  label {
    color: red;
  }
}

.input-label-content.is-invalid {
  color: #ff0000cc;

  input,
  select {
    border-bottom-color: red;
    border-color: #ff000099;
  }

  label, input::placeholder {
    color: red;
  }
}

input.is-invalid {
  border-bottom-color: red;
  border-color: #ff000099;
}
