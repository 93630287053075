@import "styles/colors.scss";

.production-rates-root {
  width: 100%;
  display: flex;
  background-color: $white;

  .production-rates-title {
    color: $dark-grey;
    margin-bottom: 10px;
  }

  .production-rates-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 20px;
  }
  .input-production-name {
    width: 100%;
  }
}
