.home {
  opacity: 1;
  touch-action: none;

  &__content {
    position: relative;
    border-radius: 8px;
    box-shadow: 0 6px 17px 0 rgba(0, 0, 0, 0.21);
    height: 70vh;
    margin: 0 auto;
    overflow: hidden;
    width: 46vh;
  }
}

.fadeIn {
  opacity: 1;
  transition: opacity 0.3s 0.2s;
}
