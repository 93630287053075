@import 'styles/colors.scss';

.account-info-root {
  width: 100%;
  display: flex;
  background-color: $white;

  .account-info-title {
    color: $dark-grey;
  }

  .account-info-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 20px;
    justify-content: center;
  }

  .select-button {
    display: flex;
    justify-content: center;
    padding: 10px;
    padding-right: 50px;
    padding-left: 50px;
    background-color: transparent;
    border: 1px solid $medium-grey;
    border-radius: 6px;
  }

  .button-selected {
    color: $white;
    background-color: $primary-light-color;
    transition: 0.2s;
  }

  .button-sameAsAbove {
    padding: 5px;
    width: fit-content;
    margin: 0px !important;
  }

  .button-space {
    margin-right: 100px;
  }

  .smartclean-tooltip {
    align-self: center;
    padding-right: 10px;
  }

  .smartclean-button-tooltip {
    border: 1px solid #216eab;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    // margin-right: 10px;
    height: 20px;
    width: 20px;
    border-radius: 20px;
  }

  .two-lines-label {
    font-size: 12px;
    width: 48px;
    line-height: 1.4;
    align-self: center;
    margin-left: 5px;
    padding-right: 10px;
  }

  .ipad-wrapper {
    @media (max-width: 769px) {
      align-self: flex-start;
      justify-content: flex-end;
    }
  }

  .days-wrapper {
    width: 100%;
    flex-wrap: wrap;
    @media (min-width: 769px) {
      display: flex;
    }
  }

  .account-info-cleaning-content {
    display: flex;
    flex-direction: column;

    .cleaning-select {
      color: #000;
      height: 39px;
      outline: none;
    }
  }
}

.validate-frequency.is-invalid button, 
.validate-porterDays.is-invalid button {
  color: red;
  border-top: 1px solid red;
  border-bottom: 1px solid red;
  border-left: 1px solid red;
}

.validate-frequency.is-invalid button:last-child,
.validate-porterDays.is-invalid button:last-child {
  border-right: 1px solid red;
}
