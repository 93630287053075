.trash-icon {
  width: 20px;
  height: 20px;
  background: url('data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAXCAYAAADpwXTaAAABo0lEQVQ4jdXVO2tWQRAG4CfxEg1GjZdEFAQvoIVFEPEH+CO0FEFBq3Qp/QMiCHZiIRYKdiGksrATCyvbiAhWihfwkniPTDInLN+yn/nsfOGws2dn3pmdMzPHXzCFh3iKRzjdX72NiSS5ilOYyf3BlsVQIYfX7fid+ys4hAv4gi24i9e4njrD+IwnHdlW3MGRytWq4rZi/wljlRavcD7IbuEkzuFtEe0vLGNjYfQz1+5dnI/jHl7KPMxUvgbDpeAJD49xFrOZm+EBaJYzl0H2bChDvo0Tler6sRAfqsvPJOZxDc97vnILEdWBLJ242YtO71jmbqJh2MJo2kVxr+VnQ657G0YtTJb2rWRvzsR2GC3kkTyv0CK7iAcp78Mcjub+PqYriz5ku7E/5YhwRxFd9OaeyqIP2WI+shMU1f8x67FCi+yf8P+RjRQJ763FsZ6yqci6kfI91685uwI/cmB2Zx+wlPK3HkcrOJ5tcXige612TNjFPFxr6Igsfhzh6UZ6DznqLaJ6h10ZwXtswk5czqI+E7cpp0MU482cBOvFm+yGBfgDagpJyEWm9sUAAAAASUVORK5CYII=')
    no-repeat;
  opacity: 0.8;
  background-position: center;
  background-size: contain;
  color: $medium-grey;
  margin: 0px;
}

.refresh-icon {
  width: 20px;
  height: 20px;
  background: url('../assets/icons/refresh.svg') no-repeat;
  opacity: 0.65;
  background-position: center;
  background-size: contain;
  color: #9a9a9a;
  margin: 0px;
  transform: scale(1.3) scaleX(-1) rotate(-45deg);
}

.auto-save-icon {
  width: 20px;
  height: 20px;
  background: url('../assets/icons/auto-save.svg') no-repeat;
  opacity: 0.65;
  background-position: center;
  background-size: contain;
  color: #9a9a9a;
  margin: 0px;
}

.copy-icon {
  width: 20px;
  height: 20px;
  background: url('../assets/icons/content-copy.svg') no-repeat;
  background-position: center;
  background-size: contain;
  color: $medium-grey;
  margin: 0px;
}

.note-icon {
  width: 20px;
  height: 20px;
  background: url('../assets/icons/edit.png') no-repeat;
  background-position: center;
  background-size: contain;
  color: $medium-grey;
  margin: 0px;
}

.add-photo-blue {
  width: 42px;
  height: 42px;
  background: url('../assets/icons/add-photo-blue.svg') no-repeat;
  background-position: center;
  background-size: contain;
  color: $medium-grey;
  margin: 0px;
}

.arrow-down-icon {
  width: 36px;
  height: 36px;
  background: url('../assets/icons/arrow-down.png') no-repeat;
  background-position: center;
  background-size: contain;
  color: $medium-grey;
  margin: 0px;
}

.arrow-up-icon {
  width: 36px;
  height: 36px;
  background: url('../assets/icons/arrow-up.png') no-repeat;
  background-position: center;
  background-size: contain;
  color: $medium-grey;
  margin: 0px;
}
