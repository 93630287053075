@import 'styles/colors.scss';

.day-porter-root {
  width: 100%;
  display: flex;
  background-color: $white;

  .day-porter-title {
    color: $dark-grey;
  }

  .day-porter-content {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 20px;
    padding-bottom: 0px;
  }

  .day-porter-row {
    display: flex;
    flex: 1;
    justify-content: space-between;
    padding: 5px;
    margin-top: 10px;

    @media (max-width: 1024px) {
      .button-days button {
        width: 55px;
      }
    }
  }
  .day-porter-price-label {
    font-size: 14px;
    width: 60px;
    margin-left: 15px;
    line-height: 1.4;
    align-self: center;
  }
  .porter-radio-button-content {
    display: flex;
    margin-left: 20px;
  }

  .porter-space-content {
    margin-top: 10px;
  }
}
