@import './variables';

@media screen and (min-width: 450px) {
  .react-calculator {
    .home {
      width: 98%;

      &__content {
        height: 88vh;
        margin: 0 auto;
        width: inherit;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .react-calculator {
    .home {
      &__content {
        height: 600px;
        position: relative;
        width: 360px;
      }
    }

    .github {
      display: inline-block;
    }
  }
}

@media screen and (min-width: 1024px) {
  .react-calculator {
    .home {
      &__content {
        height: 600px;
        position: relative;
        width: 360px;
      }
    }

    [class^='button'] {
      &:active {
        background-color: $button-base-color;
        box-shadow: inset 0 -3px 8px 0 $button-shadow;
      }

      &:nth-child(17),
      &:nth-child(18),
      &:nth-child(19) {
        &:active {
          box-shadow: inset 0 3px 8px 0 $button-shadow;
        }
      }

      &:hover:not(:active):not([class*='_primaryOperator']):not([class*='_runOperator']) {
        background-color: lighten($button-base-color, 1%);
      }

      &[class*='_primaryOperator']:hover {
        background-color: darken($primary-color, 5%);
      }

      &[class*='_runOperator']:hover {
        background-color: darken($primary-color, 2%);
      }

      &[class*='_primaryOperator']:active {
        background-color: $primary-color;
      }

      &[class*='_runOperator']:active {
        background-color: $primary-color;
        box-shadow: inset 1px 5px 9px 0 rgba(0, 0, 0, 0.37);
      }
    }
  }
}
