@import "styles/colors.scss";

.hot-button-items-root {
  width: 100%;
  display: flex;
  background-color: $grey-section;

  .hot-button-items-title {
    color: $dark-grey;
  }

  .hot-button-items-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 20px;
  }

  .button-items-input {
    display: flex;
    width: 100%;
    background-color: $white;
    align-items: center;
    margin-top: 10px;
  }
  .button-items-label {
    padding: 10px;
  }
}
