@import 'styles/colors.scss';

.input-number-root {
  display: flex;
  justify-content: center;

  .input-number {
    width: 50px;
    height: 40px;
    border: 0;
    text-align: center;
    padding: 0 8px;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .minus-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 40px;
    background-color: $medium-grey;
    font-size: 18px;
    color: $white;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border: 0;
    cursor: pointer;
  }
  .plus-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 40px;
    background-color: $primary-light-color;
    font-size: 18px;
    color: $white;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border: 0;
    cursor: pointer;
  }

  .input-disabled {
    opacity: 0.15;
  }
}
