@import "styles/colors.scss";

.restroom-fixtures-root {
  width: 100%;
  display: flex;
  background-color: $white;

  .restroom-fixtures-title {
    color: $dark-grey;
  }

  .restroom-fixtures-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 20px;
  }
  .input-fixture-name {
    width: 100%;
  }
}
