$primary-color: rgb(0, 31, 56);
$primary-light-color: #216eab;
$primary-dark-color: #003f6a;

$secondary-color: #f7a11d;

$white: #fff;
$light-grey: #fcfcfc;
$dark-grey: #3f3f3f;
$medium-grey: #9a9a9a;
$grey-background: rgb(205, 205, 205);
$grey-section: #f5f5f5;

$are-one-color: rgb(0, 176, 72);
$are-two-color: rgb(0, 96, 255);
$are-three-color: rgb(178, 156, 17);
$are-four-color: rgb(255, 140, 68);
$are-five-color: rgb(222, 0, 44);

.area-rating-color-neutral {
  color: $grey-background;
}

.area-one {
  color: $are-one-color;
}

.area-two {
  color: $are-two-color;
}

.area-three {
  color: $are-three-color;
}

.area-four {
  color: $are-four-color;
}

.area-five {
  color: $are-five-color;
}
