@import '../../../styles/colors.scss';

.notes-modal-root-div {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 99999;
}
.notes-modal-content-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: white;
    width: 87vw;
    min-height: 50vh;
    overflow: hidden;

    &.area-modal{
      min-height:166px;
      height: fit-content;
      .text-area-container{
        height: fit-content;
        border-width: 0 0 1px 0;
        //margin: 24px 0;
        //padding: 12px 36px;
        input{
          width: 100%;
          border: none;
          border-bottom: 2px solid #eeeeee;
          outline: 0;
          &.input-dropdown{
            width: calc(100% - 52px);
          }
        }
      }
      .suggestions{
        min-width: 180px;
        width: calc(78.3vw - 2px);
        // min-width: 200px;
        // width: 200px;
        position: absolute;
        z-index: 100;

        .row{
          padding-bottom: 10px;
          padding-top: 10px;
          line-height: 1.3;
          display: block;
          //height: auto;

          &:nth-of-type(even){
            background-color: $grey-background;

          }
        }
      }
    }

    .text-area-container {
        width: 90%;
        height: 38vh;
        margin: 24px;
        //padding: 12px;
        border: 1px solid $medium-grey;

        textarea {
            width: 100%;
            //height: 500px;
            border: none;
            background-color: transparent;
        }
    }

    .actions-area-container {
        display: flex;
        width: 90%;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        margin-left: 24px;


        .action-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-left: 48px;
            margin-bottom: 24px;

            span {
                font-size: 12px;
                text-decoration: underline;
            }
        }
    }
}

.notes-modal-done-button {
    position: relative;
    top: -24px;
}

.notes-modal-title-container {
    display: flex;
    width: 100%;
    height: 5vh;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: $primary-color;


    h3 {
        color: white;
    }
}
