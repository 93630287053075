@import './styles/colors.scss';
@import './styles/buttons.scss';
@import './styles/fonts.scss';
@import './styles/icons.scss';
@import './styles/modals.scss';

body {
  font-family: 'OpenSans-Regular';
  font-size: 16px;
  line-height: 24px;
  margin: 0px;
  color: #515151;

  -webkit-font-smoothing: antialiased;
}

.no-scroll {
  overflow: hidden !important;
  position: fixed;
}

h1 {
  font-family: 'OpenSans-BoldItalic';
  font-size: 40px;
  line-height: 1;
  margin: 20px 0 5px;
  color: #ffffff;
}

textarea, input, button, select { font-family: OpenSans-Regular;}
// textarea { font-family: inherit; font-size: inherit; }


input[type=number] {
  -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

h2 {
  font-family: 'OpenSans-BoldItalic';
  text-transform: uppercase;
  font-size: 30px;
  line-height: 27px;
  margin: 5px 0 10px;
  color: #ffffff;
}

h3 {
  font-family: 'OpenSans-BoldItalic';
  text-transform: uppercase;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
  text-transform: uppercase;
  color: #ffffff;
}

h4 {
  font-family: 'OpenSans-BoldItalic';
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
  margin: 0;
  text-transform: uppercase;
  text-transform: uppercase;
  text-transform: uppercase;
  color: #ffffff;
}

.sun-editor-editable h1 {
  font-family: 'OpenSans-BoldItalic';
  font-size: 40px;
  line-height: 1;
  margin: 20px 0 5px;
  color: black;
}

.sun-editor-editable h2 {
  font-family: 'OpenSans-BoldItalic';
  text-transform: uppercase;
  font-size: 30px;
  line-height: 27px;
  margin: 5px 0 10px;
  color: black;
}

.sun-editor-editable h3 {
  font-family: 'OpenSans-BoldItalic';
  text-transform: uppercase;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
  text-transform: uppercase;
  color: black;
}

.sun-editor-editable h4 {
  font-family: 'OpenSans-BoldItalic';
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
  margin: 0;
  text-transform: uppercase;
  text-transform: uppercase;
  text-transform: uppercase;
  color: black;
}

small {
  font-size: 12px;
  line-height: 17px;
  color: inherit;
}

a {
  font-family: 'OpenSans-SemiBold';

  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
  text-decoration: none;

  color: inherit;
}

address {
  font-style: normal;
}

dl {
  color: #004671;
  margin-bottom: 10px;
}

dt {
  display: inline-block;
}

dd {
  display: inline-block;
  font-family: 'OpenSans-Regular';
  font-style: italic;
  font-size: 20px;
  margin-left: 5px;
}

input:disabled {
  background-color: transparent;
}

@keyframes cssProgressActive {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 35px 35px;
  }
}

.rc-tooltip-content {
  padding-left: 20px;
  padding-right: 20px;
  max-width: 300px;
  width: 300px;
}

.rc-tooltip-arrow {
  display: none;
}

.react-datepicker__triangle {
  margin-left: 65px !important;
}

.react-datepicker-popper {
  left: -55px;
}

.tm {
  vertical-align: super;
  // font-size: 9px;
  // font-weight: bold;
}

.sm {
  font-size: initial;
  // font-weight: bold;
}

.react-pdf__Page__textContent{
  display: none !important;
}

.react-pdf__Document{
  padding-bottom: 60px !important;
}

.react-pdf__Page__canvas{
  margin: auto;
}

.container {
  width: 100%;
  overflow: auto;
}

.table {
  display: table;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0px;
}

.table-table {
  display: table-header-group;
  background-color: #F2F2F2;
}

.table-tr {
  color: inherit;
  display: table-row;
  vertical-align: middle;
  outline: 0px;
  border-bottom: 1px solid #0000001f;
}

.table-tr th {
  text-align: left;
  padding: 15px 20px;
  color: #4F4F4F;
  text-decoration: underline;
  text-underline-offset: 2px;
  text-decoration-color: #4F4F4F
}

.table-tr th .sort-icon {
  color: rgba(0, 0, 0, 0.54);
  opacity: 1;
  display: inline-block;
  transform: rotate(270deg);
  margin-left: 5px;
}

.table-tr th .sort-icon-desc {
  color: rgba(0, 0, 0, 0.54);
  opacity: 1;
  display: inline-block;
  margin-left: 5px;
  transform: rotate(90deg);
}

.table-tr th.sortable {
  cursor: pointer;
}

.table-tr td {
  text-align: left;
  padding: 15px 20px;
}

.table-tbody {
  display: table-row-group
}

.table-checkbox {
  width: 40px;
  height: 32px;
}

.table-tr button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 14px;
  border-radius: 10px;
  background-color: transparent;
  border: 2px solid rgba(0, 0, 0, 0.12);
  cursor: pointer;
  margin: auto;
}

.pagination {
  float: right;
  padding: 20px;
  display: flex;
  gap: 20px;
}

.pagination-container {
  display: flex;
  align-items: center;
}


.pagination-button {
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: transparent;
  color: #3F51B5;
  cursor: pointer;
}

.pagination-selected {
  background-color: #3f51b53d;
}

.pagination-button-right {
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: transparent;
  color: #3F51B5;
  cursor: pointer;
  transform: rotate(180deg);
}

.pagination-button-right:hover {
  background-color: #3f51b53d;
 }
 

.pagination-button:hover {
 background-color: #3f51b53d;
}


/* //////////////////////////////////////////////////////// */

$spinAnimation: (
  from: (
    transform: rotate(0deg)
  ),
  to: (
    transform: rotate(360deg)
  )
);

@keyframes spinAnimation {
  @each $key, $value in $spinAnimation {
    #{$key} {
      @each $property, $propertyValue in $value {
        #{$property}: $propertyValue;
      }
    }
  }
}

.buttonComponent-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  cursor: pointer;
  min-width: 130px;
  padding: 0px 20px;
  background-color:#216EAB;
  color: #fff;
  border: 0 none;
  border-radius: 0;
  font-size: 24px;
  position: relative;
  outline: none;
}

.buttonComponent-button.skew {
  transform: skew(28deg);
}

.buttonComponent-button.loading .buttonComponent-label {
  visibility: hidden;
}

.buttonComponent-button.rounded {
  border-radius: 5px;
}

.buttonComponent-button .loading-spinner {
  animation: spinAnimation 1.5s linear infinite;
  position: absolute;
}


.buttonComponent-button.secondary {
  background-color: #F7A11D;
  border: 2px solid #F7A11D;
}

.buttonComponent-button.secondary.outline {
  color: #F7A11D;
  background-color: #FFFF;
}

.buttonComponent-button.skew .buttonComponent-label {
  transform: skew(-28deg);
}


.buttonComponent-button.disabled {
  opacity: 0.4;
}

.buttonComponent-label {
  display: flex;
  width: 100%;
  box-align: center;
  color: inherit;
  align-items: center;
  box-pack: center;
  cursor: pointer;
  justify-content: center;
  font-size: 18px;
  font-family: OpenSans-SemiBold;
}

/* ///////////////////////////////////////////////////////////////////////////////////////////////////////// */

.button-button {
  background-color: #216EAB;
  padding: 6px 12px;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
}

.button-button.active {
  background: #EB5757;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
}


.button-button:disabled {
  opacity: 0.7;
  background: #dddddd;
}

.button-button span {
  font-size: 16px;
  font-weight: 600;
}

/* ////////////////////////////////////////////////////////////////////////////// */

.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed; 
  z-index: 1; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  background-color: rgb(0,0,0); 
  background-color: rgba(0,0,0,0.4); 
}

.modal-container-content {
  width: auto;
  height: auto;
  background-color: white;
  border-radius: 5px;
  overflow: auto; 
  max-height: 95%;
}

.preview-modal {
  width: 730px;
  height: 85vh;
  overflow-x: hidden;
}

.modal-container-content-header {
  background-color: #216EAB;
  color: white;
  padding: 15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.modal-container-content-header label {
  font-size: 20px;
  font-weight: bold;
}

.modal-container-content-body {
  padding: 30px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  position: relative;
}

.modal-supplies .modal-container-content-body {
  min-height: 90vh;
}

@media (max-width: 1024px) {
  .modal-supplies .modal-container-content-body {
    width: 85vw;
  }
}

/* //////////////////////////////////////////////////////////////////// */

.autocomplete-container {
  position: relative;
  display: inline-block;
}

.autocomplete-container.fullWidth, .autocomplete-container.fullWidth .autocomplete-row,  .autocomplete-container.fullWidth .autocomplete-input {
  width: 100%;
}

.autocomplete-label {
  font-size: 12px;
  padding-left: 0;
  color: #0000008a;
}

.autocomplete-input {
  width: 180px;
  border: none;
  border-bottom: 1px solid #0000006b;
  outline: none;
  font-size: 16px;
  padding: 5px 0px;
  height: inherit;
}

.autocomplete-input.price {
  width: 200px;
}

.autocomplete-input::placeholder {
  color: #828282;
  font-size: 16px;
}

.autocomplete-list-container {
  display: block;
  flex-direction: column;
  width: fit-content;
  position: absolute;
  z-index: 1;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100px;
}

.autocomplete-list-item {
  width: 196px;
  padding: 5px;
  cursor: pointer;
  word-wrap: break-word;
  font-size: 16px;
  cursor: pointer;
  background-color: white;
}

.autocomplete-list-item:hover {
  background-color: #F7A11D;
  color: white;
}

.autocomplete-button {
  margin-left: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  border-bottom: 1px solid #0000006b;
  padding-bottom: 5.5px;
}

.confirm-buttons {
  display: flex;
  justify-content: space-between;
  min-width: 300px;
  max-width: 700px;
  padding: 0px 20px;
}

.confirm-buttons button {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.add-supply-confirm-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
}

.restore-default-container {
  display: flex;
  margin-top: 25px;
  position: absolute;
  right: 30px;
}


@media (max-width: 1024px) {
  .restore-default-container {
    position: static;    
  }
}

.supplies-container {
  padding: 20px 0;
  text-align: center;
}

.pagination-container label {
  color: inherit;
  font-size: inherit;
}

.import-export-supplies {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 50px;
}

.supplies-container .introduction-text {
  color: #216EAB;
  font-style: italic;
  font-weight: 900;
  font-size: 24px;
  line-height: 33px;
  
  text-transform: uppercase;
}

.introduction-input {
  display: block;
  width: 100%;
  max-width: 800px;
  text-align: left;
  margin: 20px auto;
}

.introduction-input textarea {
  width: 100%;
  height: 100px;
  margin-top: 20px;
  resize: none;
  padding: 10px;
}

.introduction-input button {
  margin-left: auto;
  margin-top: 20px;
}

.introduction-input button span {
  font-size: 16px;
}
:root {
--toastify-color-progress-success: #c2e0f4 !important
}

.Toastify__close-button--light,  .Toastify__close-button--info {
  color: white !important;
  opacity: unset !important;
}

.Toastify__close-button > svg {
  fill: white !important;
}

.supplies-edit-price {
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.supplies-edit-price.end {
  justify-content: flex-end;
  margin-top: 20px;
}

.supplies-container label {
  color: inherit;
  font-size: inherit;
}

.supplies-edit-price :first-child {
  text-align: left;
  display: flex;
  align-items: center;
}

.modal-container .radio-button-content {
  align-items: center;
}

.modal-content-row {
  width: 100%;
  gap: 30px;
  display: flex;
  margin-bottom: 20px;
}

.modal-content-row.between {
  justify-content: space-between;
}

.modal-content-row.center {
  justify-content: center;
}

.add-supply-modal-body, .confirm-import-supply-modal-body {
  background: #024984
}

.add-supply-modal-body .modal-content-row, .confirm-import-supply-modal-body .modal-content-row {
  color: #ffffff;
  font-family: OpenSans-SemiBoldItalic;
  font-size: 24px;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.buttonComponent-label img {
 margin-right: 10px;
}

.edit-buttons {
  margin-top: 30px;
}

.validation-error {
  color: red;
  font-size: 12px;
  margin-top: 4px;
  width: 160px;
  text-align: left;
}

.filter-container {
  display: flex;
  align-items: center;
  margin-top: 40px;
  gap: 30px;
  text-align: left;
}

@media (max-width: 1024px) {
  .filter-container {
    width: 100%;
    gap: 10px 20px;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .filter-container > div {
    width: 202px;
  }

  .filter-container .checkItem {
    justify-content: flex-start;
  }

  .filter-container.filter-container-supply {
    width: 80%;
  }

  .edit-modal-supply {
    width: 65vw;
    max-width: none;
  }
  
  .edit-modal-supply .modal-content-row {
    justify-content: space-between;
  }
}

.filter-container .checkItem {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.filter-container .checkItem .checkItem-box {
  flex-direction: row;
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.error-supply-modal {
  justify-content: center;
  flex-direction: column;
  width: 760px;
  display: flex;
  align-items: center;
  padding: 40px;
}

.error-message {
  color: red;
  font-size: 20px;
}

.error-supply-modal .modal-content-row {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.confirm-import-supply-modal-body {
  width: 440px;
  text-align: center;
  padding: 40px;
}

.preview-button {
  margin: 50px 0px;
  text-align: center;
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 10px;
}

.preview-reference-item-content {
  height: auto !important;
}

.preview-content.with-shadow, .preview-reference-item.with-shadow {
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  margin: 10px auto 0;
  padding: 10px;
  background: #ffffff;
  width: 100%;
}

.page-supplies .preview-content {
  max-width: 880px;
}

.page-reference .preview-content {
  max-width: 900px;
  margin: auto;
}


.page-reference {
  .preview-reference-item {
    margin-bottom: 20px;
  }

  .preview-reference-item-header.white {
    &:before {
      width: 100% !important;
      box-sizing: border-box;
      height: 48px;
      padding: 0;
      border-radius: 10px 10px 0 0;
      margin: 0;
      padding: 5px 0;
    }
  }

  .preview-reference-item.with-shadow {
    padding: 0;

    .preview-reference-item-content, .preview-reference-item-content > div {
      border-radius: 10px;
    }

    .preview-reference-item-header {
      border-radius: 10px 10px 0 0;

      &:after {
        display: none;
      }
    }
  }
}

.preview-images img {
  width: 100%;
  object-fit: cover;
}

.page-supplies, .page-reference {
  width: 100%;
}

.pagination-footer-proposal {
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: -140px;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 900px;
}

@media (max-width: 980px) {
  .pagination-footer-proposal {
    width: calc(100% - 40px);
    padding: 20px;
    hr {
      width: 20px;
    }
  }
}

.dragIcon {
  pointer-events: none;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  padding: 7px 5px;
  width: 20px;
}

.dragIcon img {
  pointer-events: none;
  width: 20px;
  height: 15px;
}

.preview-close {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.preview-close img {
  width: 15px;
  height: 15px;
  cursor: pointer;
}


.modal-supplies {
  max-width: none;
}

.modal-supplies h1 {
  color: #216EAB;
  margin: 20px auto;
  text-align: center;
  font-family: 'OpenSans-RegularItalic';
  margin-bottom: 20px;
}

.preview-introduction-text h4 {
  margin: 20px 0;
  padding: 0 10px;
  font-family: 'OpenSans-Regular';
  font-size: 19.5097px;
  font-weight: 500;
  text-transform: inherit;
  color: #000000;
}

.autocomplete-row {
  display: flex;
}

.no-results {
  padding: 20px;
  font-size: 16px;
}

input {
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  appearance: none;
}

// REFERENCES

.title-text h4 {
  margin: 40px 0 15px;
  font-family: 'OpenSans-Regular';
  font-weight: 600;
  font-style: italic;
  font-size: 32px;
  color: #216EAB;
}

.header-reference {
  width: 100%;
  height: 45px;
  background-color: rgb(255, 152, 1);
  text-align: right;
  padding-top: 15px;
  position: relative;
}

.header-reference .header-logo {
  position: absolute;
  width: 0;
  height: 0;
  top: -50px;
  left: 37px;
  margin-left: -0.5em;
  box-sizing: border-box;
  border: 4em solid white;
  border-color: transparent transparent rgb(255, 152, 1) rgb(255, 152, 1);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  box-shadow: -4px 4px 4px 0 rgba(255, 255, 255, 0.2);
  z-index: 1;
}

.header-reference .header-logo-wrapper {
  position: relative;
  margin-top: -30px;
  width: 170px;
}
.header-reference .header-logo-wrapper img {
  width: 95px;
  height: auto;
  z-index: 2;
  position: absolute;
  top: 15px;
  left: 51px;
}

.preview-reference-item {
  margin-bottom: 10px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.drag-table {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}




.preview-reference-item-header {
  display: flex;
  -webkit-box-pack: start;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 5px;
  padding-right: 0px;
  padding-bottom: 5px;
  padding-left: 20px;
  color: rgb(255, 255, 255);
  background-color: rgb(248, 161, 29);
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  -webkit-box-align: start;
  align-items: flex-start;
  position: relative;
  height: 38px;
  display: flex;
  justify-content: center;
}

.preview-reference-item-content .dragIcon, .preview-reference-item-header .dragIcon {
  padding: 5px;
}

.preview-reference-item-header:before {
  content: attr(data-title);
  text-transform: uppercase;
  font-family: 'Fujiyama Italic';
  width: 87% !important;
  position: absolute;
  top: 0;
  left: 0;
  color: rgb(255, 255, 255);
  text-align: left;
  padding-left: 20px;
  font-size: 24px;
  padding-top: 5px;
  padding-right: 0px;
  padding-bottom: 5px;
  height: 40px;
  display: flex;
  align-items: center;
}

.preview-reference-item-header.withDrag:before {
  padding-left: 70px;
}

.preview-reference-item-header.blue {
  background-color: rgb(33, 110, 171) !important;
}

.preview-reference-item-header.white:before {
  border: 2px solid rgb(33, 110, 171);
  content: '';
  height: 34px;
  width: 95.5% !important;
  background-position: 160px;
  background-image: url('./assets/img/google-review.png');
  background-size: auto;
  background-repeat: no-repeat;
}

.preview-reference-item-header.white {
  background: #ffffff;
  font-family: 'Fujiyama Italic';
  padding-left: 20px;
  font-size: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.preview-reference-item-header.white span {
  font-size: 24px !important;
}

.preview-reference-item-header.white.withDrag:before {
  width: 89% !important;
  background-position: 210px;
}

.preview-reference-item-name {
  font-weight: 600;
}

.preview-reference-item-header.white:after {
  right: -5px;
  bottom: -17px;
  content: '';
  display: block;
  position: absolute;
  width: 33px;
  border-top: 0;
  height: 61px;
  background: white;
  border: 0;
  border-right: 2px solid #216eab;
  border-bottom: 0;
  -moz-transform: rotate(-45deg);
  -webkit-transform: rotate(-140deg);
}

.preview-reference-item-header.white.withDrag:after {
  right: -13px;
}


.preview-reference-item-header:after {
  content: '';
  position: absolute;
  right: -5px;
  bottom: -5px;
  border-top: 60px solid transparent;
  border-right: 55px solid white;
}

.preview-reference-item-content {
  display: flex;
  flex-direction: column;
  background: #ffffff;
}

.preview-reference-item-content .dragIcon {
  margin-right: 20px;
}

.preview-reference-item p, .preview-reference-item span {
  display: block;
  padding: 0;
  margin: 0;
}

.preview-reference-item p {
  display: block;
  font-size: 16px;
  line-height: normal;
  text-align: right;
  overflow-wrap: anywhere;
}

.preview-reference-item span {
  text-align: left;
  font-size: 17px;
  padding: 0;
  font-weight: 500;
}

p.preview-reference-item-industry {
  font-size: 12px;
}

p.preview-reference-item-contact {
  font-size: 16px;
}

.testimonial-textarea {
  width: 100%;
  height: 100px;
  resize: none;
}

.modal-content-reference .modal-content-row {
  justify-content: space-between;
}


.title-image h3 {
  text-align: center;
  font-family: 'Fujiyama Italic';
  margin-top: 60px;
  font-size: 24px;
  margin-bottom: 20px;
  color: #000000;
}

.title-image img {
  width: 100% !important;
  margin-bottom: 20px;
}

button:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.preview-link  {
  padding: 0;
  border: 0;
  background: none;
  cursor: pointer;
  display: inline-flex;
  text-decoration: underline;
  color: #0645AD;
  font-family: 'OpenSans-Regular';
}

.modal-content-container.load-template-modal {
  width: 600px;
  min-height: auto;
  margin: auto;
  height: 710px;
  justify-content: space-between;
  
  tr {
    padding: 25px 10px;
    width: 95%;
  }
}

.Toastify__toast-container {
  z-index: 999999 !important;
}