@import '../../../../../../styles/colors.scss';
@import '../../../../../../styles/base-containers.scss';

.performance-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: white;
  width: 100%;
  height: auto;
  font-family: OpenSans-SemiBoldItalic;
  color: white;
  padding-top: 15px;

  .fields-section {
    width: 100%;
    height: 220px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    align-items: center;
    background-color: #f7a11d;

    @media (max-width: 767px) {
      height: 270px;
    }

    .header-title {
      font-size: 12px;
      color: white;
      display: block;
      font-family: OpenSans-SemiBold;
    }

    .field-content {
      width: 48.5%;
      @media (max-width: 767px) {
        width: 100%;
      }
      &:first-child {
        padding-left: 25px;
        @media (max-width: 767px) {
          padding-left: 0px;
        }
      }

      &:last-child {
        @media (max-width: 767px) {
          margin-top: 10px;
        }
      }
    }

    .less-width-input {
      width: 18.5%;
    }

    .more-width-input {
      width: 78.5%;
    }

    .title {
      font-size: 28px;
      padding: 15px 0;
      margin: 0;
      width: 100%;
      margin-bottom: 10px;
      text-align: center;
      background-color: #003f6a;
      font-family: OpenSans-BoldItalic;
      position: relative;

      .hamb-menu-icon {
        top: 17px;
        left: 31px;
      }
    }

    .fields {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-left: 25px;
      margin-right: 25px;
      margin-bottom: 25px;

      input {
        width: calc(100% - 25px);

        &:first-child {
        }

        &:last-child {
        }
      }

      @media (max-width: 767px) {
        flex-direction: column;
        width: 100%;
        padding-left: 50px;

        input {
          width: calc(100% - 50px);
          margin-top: -3px;

          &:first-child {
            margin-left: 0px;
            padding-left: 0px;
          }

          &:last-child {
            margin-left: 0px;
          }
        }
      }
    }
  }

  .header-content {
    @extend %base-padding-horizontal;
    padding-top: 5px;
    padding-bottom: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100px;
    position: relative;
    padding-left: 0;
    padding-right: 0;
    text-align: center;

    p {
      color: white;
      margin: 0px;
    }

    .back-button-container {
      position: absolute;
      top: 27px;
      right: 0px;
    }
  }

  .bid-input-content {
    position: absolute;
    left: 25px;
    top: 10px;
    color: black;
    width: auto;

    select {
      color: black;
      border: 1px solid black;
      outline: none;

      option {
        background-color: white !important;
        color: black !important;
      }
    }
  }

  .bid-input-label {
    text-align: left;
  }
}
