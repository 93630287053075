@import 'styles/colors.scss';

.additional-notes-root {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 99999;
}

.additional-notes-content-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  width: 87vw;
  min-height: 50vh;
  overflow: hidden;

  .text-area-container {
    width: 90%;
    height: 38vh;
    margin: 24px;
    border: 1px solid $medium-grey;
    overflow: hidden;
    padding: 0;

    textarea {
      width: 100%;
      height: 100%;
      border: none;
      background-color: transparent;
      outline: 0;
      padding: 12px;
      box-sizing: border-box;
      font-family: 'OpenSans-SemiBold';
      font-size: 18px;
      margin-top: 6px;
      resize: none;
    }
  }

  .actions-area-container {
    display: flex;
    width: 90%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-left: 24px;

    .action-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: 48px;
      margin-bottom: 24px;

      span {
        font-size: 12px;
        text-decoration: underline;
      }
    }
  }
}

.additional-notes-done-button {
  position: relative;
  top: -24px;
}

.additional-notes-title-container {
  display: flex;
  width: 100%;
  height: 5vh;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: $primary-color;

  h3 {
    color: white;
  }
}
