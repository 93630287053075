@import 'styles/colors.scss';

.protection-disinfection-plans-root {
  width: calc(100% - 40px);
  display: flex;
  justify-content: center;
  margin: 20px;

  .protection-disinfection-plans-title {
    color: $dark-grey;
  }

  .protection-disinfection-plans-info {
    padding-left: 10px;
    color: $medium-grey;
  }

  .protection-disinfection-plans-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .button-items-input {
    display: flex;
    width: 100%;
    background-color: $white;
    align-items: center;
    margin-top: 10px;
  }
  .button-items-label {
    padding: 10px;
  }

  .accordion-content {
    display: flex;
  }

  .protection-disinfection-plans-subtitle {
    padding-top: 15px;
    font-style: italic;
  }

  .div-float-label {
    bottom: 0;
  }

  .break-label {
    .div-float-label {
      input:placeholder-shown:not(:focus) + *,
      .div-float-label select:placeholder-shown:not(:focus) + * {
        top: 0.7rem;
      }
    }
  }

  .trash-icon {
    padding: 7px;
    margin-right: 5px;
  }
}
