@font-face {
  font-family: 'OpenSans-Regular';
  src: local('OpenSans-Regular'),
    url('../assets/fonts/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSans-Bold';
  src: local('OpenSans-Bold'),
    url('../assets/fonts/OpenSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSans-Light';
  src: local('OpenSans-Light'),
    url('../assets/fonts/OpenSans-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSans-LightItalic';
  src: local('OpenSans-LightItalic'),
    url('../assets/fonts/OpenSans-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSans-RegularItalic';
  src: local('OpenSans-RegularItalic'),
    url('../assets/fonts/OpenSans-RegularItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSans-SemiBold';
  src: local('OpenSans-SemiBold'),
    url('../assets/fonts/OpenSans-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSans-SemiBoldItalic';
  src: local('OpenSans-SemiBoldItalic'),
    url('../assets/fonts/OpenSans-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSans-BoldItalic';
  src: local('OpenSans-BoldItalic'),
    url('../assets/fonts/OpenSans-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSansCondensed-LightItalic';
  src: local('OpenSansCondensed-LightItalic'),
    url('../assets/fonts/OpenSansCondensed-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Fujiyama Italic';
  src: local('Fujiyama Italic'),
    url('../assets/fonts/Fujiyama Italic.ttf') format('truetype');
}