@import 'styles/colors.scss';

.cancellation-clause-root {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: $grey-section;
  position: relative;

  .cancellation-clause-title {
    color: $dark-grey;
    margin-left: 20px;
    margin-top: 20px;
  }

  .warning-block {
    background: #f30b0b;
    color: white;
    padding: 20px;

    margin-bottom: 20px;
    .cancellation-clause-title {
      margin-left: 0px;
      color: white;
      margin-top: 0px;
    }
    .message {
      margin-left: 0px;
      font-family: 'OpenSans-RegularItalic';
    }
  }

  .cancellation-clause-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }

  .cancellation-row {
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-bottom: 10px;
    margin-top: 5px;
  }

  .cancellation-column {
    display: flex;
    flex-direction: column;
    width: 40%;
    justify-content: center;
    align-items: center;
  }

  .cancellation-label {
    margin-bottom: 10px;
  }

  .modifications-message-wrapper {
    position: relative;
    width: 100%;
  }

  .modifications-message {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f30b0b;
    top: 0;
    flex-direction: column;
    padding: 5px 0;

    @media (min-width: 1108px) {
      padding: 15px 0;
    }

    width: 100%;
    max-width: 1200px;

    .title {
      text-align: center;
      color: black;
      font-size: 14px;
    }

    .message {
      text-align: center;
      color: white;
      width: 95%;
      font-size: 14px;
    }
  }
}
